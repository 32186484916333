import React, { useRef, useState } from "react";
import footline from "../Assests/images/footline.png";
import ftcopy from "../Assests/images/ftcopy.png";
import logo from "../Assests/Logo.png";
import venkat from "../Assests/Logo-Footer.png";
import footimg from "../Assests/images/footing.png";
import {
  IoCallOutline,
  IoLocationOutline,
  IoStarHalfOutline,
} from "react-icons/io5";
import { IoIosStar, IoMdMail } from "react-icons/io";
import { HiMiniHandThumbUp } from "react-icons/hi2";
import { FaLinkedin, FaSquareInstagram, FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { PiHandTapFill } from "react-icons/pi";
import { DatePicker, Form, Input, InputNumber, TimePicker, message } from "antd";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AppointmentService from '../Service/AppointmentService';

function Footer() {
  const facebookPageUrl = "https://www.facebook.com";
  const twitterPageUrl = "https://www.x.com"
  const instagramPageUrl = "https://www.instagram.com";
  const linkedinPageUrl = "https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A101817834&keywords=venkataramana%20ayurveda&origin=RICH_QUERY_SUGGESTION&position=0&searchId=e3cdea77-dc55-4070-8a1b-82ae7a6acde8&sid=Alm&spellCorrectionEnabled=false"
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const formRef = useRef();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  // State to manage form submission status
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const { name, phone,email, reason } = formRef.current.getFieldValue();
console.log(formRef.current.getFieldValue())
    if (name === "" || name === undefined || name === null) {
      message.error("Please fill in all fields");
      return;
    }

    if (phone === "" || phone === undefined || phone === null) {
      message.error("Please fill in all fields");
      return;
    }

    if (reason === "" || reason === undefined || reason === null) {
      message.error("Please fill in all fields");
      return;
    }

    if (selectedDate === "" || selectedDate === undefined || selectedDate === null) {
      message.error("Please fill in all fields");
      return;
    }

    if (selectedTime === "" || selectedTime === undefined || selectedTime === null) {
      message.error("Please fill in all fields");
      return;
    }

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let payload = {
      code: "string",
      createdBy: "string",
      createdDate: constructedTime,
      email,
      givenDate: selectedDate,
      givenTime: selectedTime,
      message: reason,
      mobileNumber: phone,
      modifiedBy: "string",
      modifiedDate: constructedTime,
      name: name,
      status: 1
    }

      await AppointmentService.bookAppointment(payload);
      formRef.current.resetFields();
    

  };

  const handleKeyPress = (e) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (!allowedKeys.includes(e.key)) {
      if (/^[A-Za-z]$/.test(e.key)) {
        message.error("Alphabetic characters are not allowed.");
      }
      e.preventDefault();
    }
  };

  const handleClickHome = () => {
    navigate('/')
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }

  const handleClickAboutus = () => {
    navigate('/about')
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }
  const handleClickProduct = () => {
    navigate('/product')
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }
  const handleClickContactus = () => {
    navigate('/contact')
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }


  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
    const selectedDateVal = dateString.replaceAll('-','/').split("/");
    const finalDateVal = `${selectedDateVal[1]}/${selectedDateVal[2]}/${selectedDateVal[0]}`;

    console.log(finalDateVal)
    setSelectedDate(finalDateVal);
  };

  const onTimeChange = (time, timeString) => {
    console.log(time, timeString);
    setSelectedTime(timeString.split(" ")[0]);
  };

  return (
    <div>
      <footer>
        <div>
          <img src={footline} className="w-full" alt="img" />
        </div>
        <div
          className="md:w-full  md:h-44"
          style={{ backgroundImage: `url(${ftcopy}) ` }}
        >
          <div className="md:flex w-full  items-center justify-evenly">
            <div className="md:w-80  flex flex-col justify-center items-center md:ml-5 ">
            <img className="w-[30%] cursor-pointer" src={logo} alt="logo" />
            <img className="w-[100%] cursor-pointer" src={venkat} alt="venkat" />
            </div>
            <div className="md:bg-transparent bg-[#af9550] md:w-[65%] md:py-0 py-1">
              <img
                src={footimg}
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <img src={footline} className="w-full" alt="img" />
        </div>
        <div className="w-full h-full bg-[#AF9550]">
          <div className="md:flex p-3 justify-evenly">
            <div className="md:w-[28%] mt-3">

              <div className="">
                <label className="uppercase text-xl font-medium text-white justify-center flex">
                  book appointment
                </label>
                <Form ref={formRef}>
                  <div className="border shadow-md  bg-white p-3 rounded-lg mt-4">
                    <div className="grid">
                      <label className="text-[#492F00]">Name<span className=" text-red-500 text-md">*</span></label>
                      <Form.Item name="name">
                        <Input
                          // type="text"
                          className="border border-gray-500 h-10 rounded-full focus:outline-none placeholder-gray-300 bg-gray-100 text-justify container"
                          // placeholder="Enter your Name"
                          // value={formData.name}
                          // onChange={handleInputChange}
                          required
                        />
                      </Form.Item>
                    </div>
                    <div className="">
                      <div className="">
                        <label className="text-[#492F00]">Phone<span className=" text-red-500 text-md">*</span></label>
                        <Form.Item name="phone">
                          <InputNumber
                            onKeyPress={handleKeyPress}
                            maxLength={10}
                            // type="text"
                            className="border border-gray-500 h-10 rounded-full focus:outline-none placeholder-gray-300 bg-gray-100 container"
                            // placeholder="Enter your Number"
                            // value={formData.phone}
                            // onChange={handleInputChange}
                            required
                          />
                        </Form.Item>
                      </div>
                      
                    </div>


                    <div className=" grid grid-cols-2 gap-2">
                      <div className="flex flex-col">
                        <label className="text-[#492F00]">Date <span className=" text-red-500 text-md">*</span></label>
                        <Form.Item name="date">
                          <DatePicker onChange={onDateChange} />
                        </Form.Item>
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#492F00]">Time<span className=" text-red-500 text-md">*</span></label>
                        <Form.Item name="time">
                          <TimePicker format="HH:mm" onChange={onTimeChange} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="grid">
                      <label className="text-[#492F00]">Reason <span className=" text-red-500 text-md">*</span></label>
                      <Form.Item name="reason">
                        <TextArea
                         
                          className="border border-gray-500 h-10 rounded-2xl focus:outline-none resize-none placeholder-gray-300 bg-gray-100 text-start container"
                          // placeholder="Enter the Message"
                          // value={formData.message}
                          // onChange={handleInputChange}
                          required
                        />
                      </Form.Item>
                    </div>
                    <button
                      onClick={handleSubmit}
                      className="focus:outline-none bg-[#604c19] h-10 mt-3 w-full rounded-md text-white font-medium uppercase"
                    >
                      Submit
                    </button>{" "}
                  </div>
                </Form>

              </div>
            </div>
            <div className="mt-3">
              <h2 className="text-xl text-white font-medium uppercase">
                quick links
              </h2>
              <nav class="list-none cursor-pointer text-md mt-3">
                <li className="mt-3">
                  <a class="text-[whitesmoke] hover:text-[#854e07]" onClick={handleClickHome}>Home</a>
                </li>
                
                <li className="mt-3">
                  <a class="text-[whitesmoke] hover:text-[#854e07]" onClick={handleClickAboutus}>About Us</a>
                </li>
                <li className="mt-3">
                  <a class="text-[whitesmoke] hover:text-[#854e07]" onClick={handleClickContactus}>Contact Us</a>
                </li>
               
              </nav>
            </div>
          
            <div className="mt-3">
              <h2 className="text-xl text-white font-medium uppercase">
                contact
              </h2>
              <nav class="list-none mt-4 text-sm">
                <li className="flex mt-1">
                  <IoLocationOutline className="text-white w-6 h-6" />
                  <span class="text-white ml-3">No 138,</span>
                </li>
                <li className="mt-1">
                  <span class="text-white ml-9">
                    Kutchery Road, Girija Garden,
                  </span>
                </li>
                <li className="mt-1">
                  <span class="text-white ml-9">Mylapore, Chennai-600004</span>
                </li>
                <li className="mt-1">
                  <span class="text-white ml-9">Tamil Nadu.</span>
                </li>
                <li className="flex mt-2">
                  <IoCallOutline className="text-white w-5 h-5 mt-1" />
                  <span class="text-white ml-4">+91 044 24984513</span>
                </li>
                <li className="flex mt-2">
                  <IoMdMail className="text-white w-5 h-5 mt-1" />
                  <span class="text-white ml-4">
                  venkataramanaayurveda@gmail.com
                  </span>
                </li>
                <div className="mt-3 h-12 border-t border-b">
                  <PiHandTapFill className="absolute text-white w-7 h-7 mt-2" />
                  <span class="absolute font-medium text-md text-gray-300 mt-[0.75rem] ml-8">
                    Follow on
                  </span>
                  <div className="absolute mt-[0.75rem] ml-[8.5rem] cursor-pointer">
                    <a href={facebookPageUrl}>
                      <FaFacebook className="w-5 h-5 text-white" />
                    </a>
                  </div>
                  <div className="absolute mt-[0.70rem] ml-[11rem] cursor-pointer">
                    <a href={twitterPageUrl}>
                    <FaXTwitter  className="w-5 h-5 text-white" />
                    </a>
                  </div>
                  <div className="absolute mt-[0.70rem] ml-[13.5rem] cursor-pointer">
                    <a href={instagramPageUrl}>
                      <FaSquareInstagram className="w-5 h-5 text-white" />
                    </a>
                  </div>
                  <div className="absolute mt-[0.70rem] ml-[16rem] cursor-pointer">
                    <a href={linkedinPageUrl}>
                      <FaLinkedin className="w-5 h-5 text-white" />
                    </a>
                  </div>
                </div>
                <div className="flex mt-[1.5rem]">
                  <HiMiniHandThumbUp className="text-white w-6 h-6" />
                  <span className="text-white ml-3 text-lg">4.3</span>
                  <IoIosStar className="text-yellow-300 ml-1 mt-1" />
                  <IoIosStar className="text-yellow-300 ml-1 mt-1" />
                  <IoIosStar className="text-yellow-300 ml-1 mt-1" />
                  <IoIosStar className="text-yellow-300 ml-1 mt-1" />
                  <IoStarHalfOutline className="text-yellow-300 ml-1 mt-1" />
                  <span className="text-gray-300 ml-2">53 Google reviews</span>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div>
          <img src={footline} className="w-full" alt="yhiji" />
        </div>
        <div
          className="w-full flex h-10 items-center"
          style={{ backgroundImage: `url(${ftcopy}) ` }}
        >
          <p class="w-full flex justify-center text-xs text-white">
            © Copyright 2024 Venkataramana Ayurveda 
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;