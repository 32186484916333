import React from "react";
import Layouts from "../../Layouts";
import image from '../../../Assests/images/MahatmaGandhi.png';
import image1 from '../../../Assests/images/Venkataramana Iyer.png'
import college from '../../../Assests/Sanskrit-College.jpg'
import border from '../../../Assests/images/BorderDesign.png';
import bank from '../../../Assests/indian bank.gif';
import venkatramana from '../../../Assests/Since1959.png'
import gandhi from '../../../Assests/gandhi.png'
import vivekanandha from '../../../Assests/Swami Vivekanada.jpeg'
import barathi from '../../../Assests/Subramania-Bharati.webp'
import beach from '../../../Assests/marina-beach.jpg'
import krishna from '../../../Assests/krishnaswamy.jpg'
import temple from '../../../Assests/temple.jpeg'
import statue from '../../../Assests/Iyer.jpg'

const About = () => {
  return (
    <Layouts>
      <div>
        <img src={image} alt="3" className="responsive-image w-full" />
        <div className="mt-0 mb-16 ">
          <div className="md:min-h-[500px] md:flex justify-center items-center backdrop-blur-xl md:py-12 py-4 sm:py-0 ">
            <div>
              <div className="text-center gap-1 w-full md:h-[160px] h-[120px] bg-[#FFF0C0]">
                <h1 className="text-brown font-bold pt-3  md:text-[1.75rem]">
                  TRUST IN AYURVEDA MEDICINES IN  THE PAST 118 YEARS
                </h1>
                
                <p className="font-medium md:text-[1.25rem] mt-1">About us</p>
                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>
              <div className=" md:my-5   md:p-0 p-4 ">
                <p className="md:text-3xl text-xl font-semibold flex justify-center">SRI V. KRISHNASWAMY IYER</p>

                <div className="md:flex items-center justify-center ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="md:leading-8 leading-7  text-justify font-normal text-base">Sri V. Krishnaswamy Iyer was an Indian lawyer and High Court Judge of Madras.
                      He was the first Indian to have a beach front statue in the Madras University in
                      Chennai in 1912. He was instrumental for a number of educational reforms in
                      University Of Madras.
                    </p>
                  </div>
                  <div className=" ">
                    <img
                      src={image1}
                    />
                  </div>

                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-1 w-full md:h-[100px] h-[100px] bg-[#FFF0C0]">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  FOUNDING OF THE INDIAN BANK

                </h1>
                <img className="mt-2" src={border} alt="border" />
              </div>
              <div className=" md:my-5 my-2 md:p-0 p-4 ">


                <div className="md:flex items-center justify-center ">
                  <div className=" ">
                    <img
                      src={bank}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] flex justify-center  md:mr-16 lg:ml-11"
                    />
                  </div>
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className=" text-justify font-normal text-basemd:leading-8 leading-7"> Indian Bank was incorporated in the
                      year 1907 by V. Krishnaswamy Iyer, a
                      dynamic lawyer who led a Swadeshi
                      movement against British Rule, brought
                      down the British Run 'Arbuthnot Bank'
                      and subsequently established the Indian
                      Bank with Chettiar Capital. The bank
                      was later nationalized by the
                      Government of India.
                    </p>
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base py-3">The bank initiated its operations on 15 August 1907 along with its head office in
                      Parry’s Building, Parry's Corner, Madras. The bank soon expanded internationally,
                      opening a branch in Colombo, Sri Lanka in 1932 and in Rangoon, Burma in 1940</p>
                  </div>


                </div>
              </div>
              <div className="flex flex-col items-center gap-1 w-full md:h-[100px] h-[120px] bg-[#FFF0C0] justify-center">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  MADRAS SANSKRIT COLLEGE
                </h1>
                <img className="mt-2" src={border} alt="border" />
              </div>

              <div className="md:my-[5rem] md:mx-[5rem]">

                <div className="my-12 md:p-0 p-4 md:mx-[3rem]">
                  <p className="text-justify font-normal md:leading-8 leading-7 text-base"> When he was a struggling lawyer, V.
                    Krishnaswamy Iyer had spent his surplus
                    energies in the serious perusal of
                    Sanskrit texts. Appalled at the way in
                    which several schools of philosophy
                    such the Purva Mimamsa were dying
                    out, he set up the Madras Sanskrit
                    College in 1906 in Chennai. </p>
                  <div className="float-right">
                    <img
                      src={college}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] md:ml-5"
                    />
                  </div>
                  <p className="text-justify font-normal text-base md:leading-8 leading-7 py-3">He wanted to promote Sanskrit as he was greatly upset at the way Sanskrit shlokas
                    and hymns were being recited at temples. The Madras Sanskrit College still runs
                    today at the heart of Chennai, Mylapore. This institution began life in Pelathope and
                    thanks to the munificent donations of several, including the founder, moved to its
                    present location in 1910. It was thanks to V. Krishnaswamy Iyer that the College got
                    its library with 800 books in the first year. He was also instrumental in getting S.
                    Kuppuswami Sastri to give up all aspirations of rising in the Bar and take up
                    Sanskrit. Sastri became the first Principal of the College. </p>
                  <p className="text-justify font-normal md:leading-8 leading-7 text-base py-3">Today, a bust of V. Krishnaswamy Iyer stands in the college campus. Surrounding
                    the old building are several new structures, all built with donations from the
                    founder's descendants and juniors in law. At the University, Krishnaswamy Iyer
                    lobbied hard for Sanskrit to be recognized as a subject for acquiring a degree and
                    this was recognized in 1910. </p>
                  <p className="text-justify font-normal md:leading-8 leading-7 text-base py-3">His love for Tamil was no less. In music, he was partial to the singing of Tevarams
                    and Tiruvachakams. He was known for lecturing in Tamil and at one such speech at
                    the Madras University, Subramania Bharati is said to have been particularly
                    impressed. He played an important role in gathering funds for the Central Hindu
                    University (now the Benares Hindu University)</p>


                </div>
              </div>


              <div className="text-center gap-1 w-full  h-[110px] bg-[#FFF0C0] md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  VENKATARAMANA AYURVEDA COLLEGE AND DISPENSARY
                </h1>
                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>
              
              <div className=" md:my-5 my-2 md:p-0 p-4 ">
                <div className="md:flex items-center justify-center ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base"> In
                      1905, he established the
                      Venkataramana Ayurveda College and
                      Dispensary, to revive the traditional
                      Indian School of Medicine and promote
                      Ayurvedic Science. He endowed the
                      institution with a grant of Rs 20,000 and
                      it functions even today standing tall in
                      Mylapore, Chennai.
                    </p>
                    <p className="leading-8 text-justify font-normal text-base py-3">Sri V. Krishnaswamy Iyer became a
                      judge of the Madras High Court in 1909,
                      appointed by the then governor of
                      Madras, Arthur Lawley.</p>
                  </div>
                  <div className=" ">
                    <img
                      src={venkatramana}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] flex justify-center  md:ml-16 lg:ml-11"
                    />
                  </div>

                </div>
                <div className="my-4 text-center">
                  <p className="md:text-2xl font-semibold">MAHATMA GANDHI VISITING VENKATARAMANA AYURVEDA COLLEGE AND DISPENSARY IN 1915 AND CALLING </p>
                  <p className="md:text-2xl font-semibold">V. KRISHNASWAMY IYER 'MAHAPURUSHA'</p>
                </div>
                <div className="md:flex items-center justify-center ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base"> In the year 1915, Mahatma Gandhi visited Venkatramana Ayurveda College and
                      Dispensary and was in awe of V. Krishnaswamy Iyer’s contribution to the Swadeshi
                      Movement and Indian Medicine. He gave him the Title “Mahapurusha” after seeing
                      his contribution to Indian Medicine.
                    </p>

                  </div>
                  <div className=" ">
                    <img
                      src={gandhi}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] flex justify-center  md:ml-16 lg:ml-11"
                    />
                  </div>

                </div>

              </div>
              <div className=" text-center h-[100px] bg-[#FFF0C0] justify-center">
                <h1 className="text-brown font-bold md:text-[1.75rem] p-2">
                  SRI V. KRISHNASWAMY IYER'S RELATIONSHIP WITH SWAMI VIVEKANANDA
                </h1>
                

                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>
              <div className=" md:my-5 my-2 md:p-0 p-4 ">


                <div className="md:flex items-center justify-center ">
                  <div className=" ">
                    <img
                      src={vivekanandha}
                      alt="ayur img"
                      className="md:max-w-[353px] md:h-[300px] flex justify-center  md:mr-16 lg:ml-11"
                    />
                  </div>
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className=" text-justify font-normal text-base md:leading-8 leading-7">  Sri V. Krishnaswamy Iyer after meeting and
                      interacting with Swami Vivekananda, greatly
                      admired his knowledge of Hindu Philosophy.
                      He funded Swami Vivekananda's famous trip to
                      Chicago in 1893, where the speech  he gave
                      remains immortal even to this day.
                    </p>

                  </div>



                </div>
              </div>
              <div className="text-center  h-[100px] bg-[#FFF0C0] justify-center md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  SRI V. KRISHNASWAMY IYER'S RELATIONSHIP WITH SUBRAMANIA BHARATHI
                </h1>
               

                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>

              <div className=" md:my-5 my-2 md:p-0 p-4 ">

                <div className="md:flex items-center justify-center ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="md:leading-8 leading-7 leading-78 text-justify font-normal text-base"> Sri V. Krishnaswamy's house 'Ashrama'
                      had many distinguished visitors, one of which was Subramania Bharathi. He was brought here by noted publisher G.A. Natesan,a neighbour of Krishnaswamy Iyer.
                    </p>
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base"> Krishnaswamy Iyer did not like
                      Bharathi’s radical editorials and had
                      formed a negative view of the poet
                      without ever meeting him. Bharathi was
                      therefore not introduced and was merely
                      asked to sing on a cue from Natesan.
                    </p>
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base"> He was hardly into his second poem when Krishnaswamy Iyer shed tears at the
                      beauty of what he had just heard. He was to become a great admirer from then on.
                      The first publication of Bharathi’s songs was funded by V. Krishnaswamy Iyer.
                    </p>
                  </div>
                  <div className=" ">
                    <img
                      src={barathi}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] flex justify-center  md:ml-16 lg:ml-11"
                    />
                  </div>

                </div>
              </div>

              <div className="text-center h-[100px] bg-[#FFF0C0] justify-center md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  SRI V. KRISHNASWAMY IYER SAVING THE MARINA BEACH
                </h1>
               

                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>
              <div className=" md:my-5 my-2 md:p-0 p-4 ">


                <div className="md:flex items-center justify-center ">
                  <div className=" ">
                    <img
                      src={beach}
                      alt="ayur img"
                      className="md:max-w-[353px] md:h-[300px] flex justify-center  md:mr-16 lg:ml-11"
                    />
                  </div>
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className=" text-justify font-normal text-base md:leading-8 leading-7">  In 1903, the South Indian Railway decided to build a railway line along the beach.
                      The Corporation gave its sanction and work was all set to begin, when on 1st April,
                      V. Krishnaswamy Iyer organized a massive public protest opposing the Railway line
                      on the beach. As the protest grew big, sensing the public mood, the Corporation
                      withdrew its consent.
                    </p>
                    <p className=" text-justify font-normal text-base md:leading-8 leading-7">  The railways offered all the standard SOP, some of which are still given out – the
                      stations would not be eyesores, and there would be a protective hedge that would
                      also hide the track etc. But Krishnaswamy Iyer was implacable and refused to
                      withdraw the protest. Therefore, the proposal was abandoned and the Marina was
                      saved
                    </p>

                  </div>



                </div>
              </div>

              <div className="text-center bg-[#FFF0C0] justify-center md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  SRI V. KRISHNASWAMY IYER’S RELATIONSHIP WITH KANCHI CHANDRASHEKHAR SARASWATHI
                </h1>
               

                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>

              <div className=" md:my-5 my-2 md:p-0 p-4 ">

                <div className="md:flex items-center justify-center ">
                  <div className="md:w-[45%] flex flex-col gap-4 items-center">
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base">  Sri V. Krishnaswamy Iyer had tremendous
                      respect and affinity towards Hindu Mutts, so
                      much so that when the Paramacharya of
                      Kanchi Chandrashekhara Saraswathi became
                      pontiff at a very young age, V. Krishnaswamy
                      Iyer was worried that the administration of the
                      mutt would fall into wrong hands who would
                      manipulate the child. He convinced the High
                      Court that the mutt needed to be administered
                      by the Court of Wards till the pontiff attained
                      majority.
                    </p>
                    <p className="md:leading-8 leading-7 text-justify font-normal text-base"> When a Devotee asked Chandrasekhar Saraswathi who the most Dharmic person he
                      has ever encountered in his life was, Swami Chandrasekhar Saraswathi said "I have
                      met many rich people and also come across people who follow Dharmic Principles;
                      But V. Krishnaswamy Iyer is the only person I have met who is wealthy and also
                      upholds Dharma."
                    </p>

                  </div>
                  <div className=" ">
                    <img
                      src={krishna}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] flex justify-center  md:ml-16 lg:ml-11"
                    />
                  </div>

                </div>
              </div>


              <div className="text-center h-[100px] bg-[#FFF0C0] justify-center md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  SRI V. KRISHNASWAMY IYER’S ROLE IN PRESERVATION OF HINDUISM
                </h1>
                
                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>

              <div className="md:my-[5rem] md:mx-[5rem]">

                <div className="my-12 md:p-0 p-4 md:mx-[3rem]">
                  <p className="text-justify font-normal text-base"> V. Krishnaswamy Iyer expressed his concern at the vast landholdings of temples
                    bringing in no income and called for better administration. </p>
                  <div className="float-right">
                    <img
                      src={temple}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] md:ml-5"
                    />
                  </div>
                  <p className="text-justify font-normal text-base leading-6 py-3">Greatly upset at the way Sanskrit hymns
                    and shlokas were not being recited at
                    temples, he had them compiled and
                    published at his own expense and
                    distributed free to shrines all over Madras
                    Presidency. </p>
                  <p className="text-justify font-normal text-base py-3">He wrote to the Jagatguru of the Sringeri
                    Sarada
                    Peetam,
                    Sacchidananda
                    Shivabhinava Nrisimha Bharati Swamigal,
                    imploring him to intervene and set right the
                    falling standards of Hindu dharma. At that
                    pontiff’s suggestion he set up the Dharma
                    Rakshana Sabha in 1903. </p>
                  <p className="text-justify font-normal text-base py-3">His love for Tamil was no less. In music, he was partial to the singing of Tevarams
                    and Tiruvachakams. He was known for lecturing in Tamil and at one such speech at
                    the Madras University, Subramania Bharati is said to have been particularly
                    impressed. He played an important role in gathering funds for the Central Hindu
                    University (now the Benares Hindu University)</p>

                  <ul> Other key points:</ul>
                  <li> Sri V. Krishnaswamy Iyer played an important role in the civic welcome given
                    to Swami Vivekananda in 1897</li>
                  <li> Sri V. Krishnaswamy Iyer also funded the Ramakrishna Math and the Boys
                    home in the initial years.</li>
                  <li>Sri V. Krishnaswamy Iyer was also awarded the Kaiser-I-Hind gold medal for
                    his philanthropic activities.</li>
                  <li>In 1911, he was appointed member of the Executive Council of the Governor of Madras.</li>
                </div>
              </div>

              <div className="text-center h-[100px] bg-[#FFF0C0] md:p-4 p-2">
                <h1 className="text-brown font-bold md:text-[1.75rem]">
                  SRI V. KRISHNASWAMY IYER’S ROLE IN THE FREEDOM MOVEMENT OF INDIA
                </h1>
                
                <div className="flex justify-center">
                  <img className="mt-2" src={border} alt="border" />
                </div>
              </div>

              <div className="md:my-[5rem] md:mx-[5rem]">

                <div className="my-12 md:p-0 p-4 md:mx-[3rem]">
                  <p className="text-justify font-normal text-base">  Sri V. Krishnaswamy Iyer's House 'Ashrama' witnessed the leading greats and
                    freedom fighters of India. He was a great admirer of Gokhale.
                    In the year 1884, a group had met and resolved to begin a political movement,
                    which culminated in the founding of the Congress Party a year later. Under the
                    influence of Sir S. Subramania Iyer who was active in the affairs of the Congress.  </p>
                  <div className="float-right flex flex-col">
                    <img
                      src={statue}
                      alt="ayur img"
                      className="md:max-w-[483px] md:h-[450px] ml-5"
                    />
                    <p className="font-semibold "> Sri V. Krishnaswamy Iyer's statue at Madras University</p>
                  </div>
                  <p className="text-justify font-normal text-base  py-3">In its early years, both Krishnaswami Iyer and PR Sundara Iyer were to become
                    members. Krishnaswami Iyer was to be an active volunteer in organizing the 1897
                    Congress Session in Madras. By 1903, when the next Session in Madras came
                    about, he had grown wealthy and influential. He embarked on a whirlwind tour of
                    South India to collect funds for the Congress. He was of the view that a party that
                    represented the common man should also be funded by him. This was the seed of
                    the idea that Gandhi later took to great heights – a 25 paisa membership fee from
                    every Indian to support the Congress. </p>
                  <p className="text-justify font-normal text-base py-3 "> With a view to attracting crowds to the 1903 Session, Krishnaswami Iyer mooted
                    the idea of an exhibition. This was inaugurated by the Maharajah of Mysore. A
                    cyclone threatened to wreck the Session but thanks to the foresight of
                    Krishnaswami Iyer, the venue was made waterproof. Several delegates were housed
                    in tents on the grounds of his vast garden bungalow Ashrama on Luz Church Road. </p>
                  <p className="text-justify font-normal text-base py-3 "> His efforts were to earn him the praise of men such as D.E. Wacha, Dadabhai
                    Nauroji and Gopalakrishna Gokhale. The latter was to become a firm friend, who
                    stayed at Ashrama whenever he visited Madras. Krishnaswami Iyer was to become
                    a Gokhale follower, thereby being labeled a Moderate.</p>

                  <p className="text-justify font-normal text-base py-3 "> His involvement in public affairs drew him to the Congress party. He was
                    instrumental in bringing together the moderate and extremist factions of the
                    Congress Party at the 1908 session in Madras. This act of Krishnaswamy Iyer was
                    greatly appreciated by Gokhale.</p>


                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default About;