import { message } from "antd";
import http from "./http_common";

class ContactService{
    async contactUs(payload){
        try{
            http.post('/contactForm/',payload);
            message.success("Thank You! Our support team will contact you shortly.");
        }
        catch(e){
            message.error("Error Occurred");
        }
    }

   async addAddress(payload){
        try{
            http.post('/userDeliveryAddress/',payload);
        }
        catch(e){
            message.error("Error Occurred");
        }
    }

}

export default new ContactService();