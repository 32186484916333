import React, { useEffect, useRef, useState } from 'react';
import Layouts from '../../Layouts';
import line from '../../../Assests/images/line.png'
import radio from '../../../Assests/images/Button.png'
import BannerImg from '../../../Assests/images/Banner.png';
import features from "../../../Assests/images/Features.png"
import ingredients from '../../../Assests/images/natural Ingredients.png'
import Holistic from '../../../Assests/images/Holistic Approach.png'
import Personalized from '../../../Assests/images/Personalized Care.png'
import Balance from '../../../Assests/images/Promotion of Balance.png'
import TimeTested from '../../../Assests/images/Time-Tested Tradition.png'
import Complementary from '../../../Assests/images/Complementary Healthcare.png'
import herbes from '../../../Assests/images/Herbs.png'
import AuthenticationService from "../../../Service/Authentication"


const Home = () => {

  const getToken = () => {
    AuthenticationService.AthenticationToken().then((res) => {
      console.log(res)
      localStorage.setItem( "access_token",res.data.access_token)
    })
  }

  useEffect(() => {
    getToken()
  })


  return (
    <>
      <Layouts>

        <div className='min-h-screen '>
          <section className='min-h-[85vh]  bg-cover bg-no-repeat bg-center py-[50px] relative' style={{ backgroundImage: `url(${BannerImg})` }}>
            <img className=' w-full h-2 absolute top-0' src={line} alt='1' />

            <div className='container mx-auto py-4  md:px-4 md:p-3'>
              <h1 className='md:text-5xl text-xl text-textWhite font-medium mb-3'>
              EMBRACE HOLISTIC WELLNESS 
              </h1>
              {/* <h1 className='md:text-5xl text-2xl text-textWhite font-base mb-3'>
                PATH TO WELL-BEING
              </h1> */}
              <p className='md:w-[50%] text-justify  text-textWhite font-light'>
                Embark on a journey of holistic wellness with us. We blend ancient wisdom with modern expertise to offer you personalized pathways to health and vitality. Explore our range of Ayurvedic therapies and herbal remedies tailored to rejuvenate your mind, body, and spirit.
              </p>
            </div>
            <img className=' w-full h-2 absolute bottom-0' src={line} alt='1' />
          </section>
          <section className="bg-[#FBF3E6]">
            <div className="container mx-auto md:p-0 p-4 py-[50px] md:py-[90px]">
              <div className="md:flex justify-between items-center">
                <div className="md:w-[60%]">
                  <h6 className="text-primaryColor font-medium md:text-2xl text-lg mb-2">Who we are?</h6>
                  <p className=" text-justify  mb-4 md:w-[80%]">Venkataramana Ayurveda harnesses Ayurvedic wisdom for comprehensive wellness. With experienced practitioners and authentic therapies, we empower individuals to achieve balance and vitality naturally. Where ancient wisdom meets modern wellness, guiding you to a harmonious life through the transformative power of Ayurveda.
                  </p>
                  <div >
                    <ul className="md:grid grid-cols-2 gap-2 ">
                      <li className="flex gap-2  items-center"><img className='h-4 w-4 mt[3px]' src={radio} alt='/' />
                        Premium Branding</li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' />Ensuring Rigorous Quality Assurance </li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' /> 100% Pure</li>
                      <li className="flex gap-2  items-center"> <img className='h-4 w-4 mt[3px]' src={radio} alt='/' /> No Artifical Flavours or Preservative</li>
                    </ul>
                  </div>

                </div>
                <div className="md:w-[40%] md:h-[350px] md:p-0 p-4">
                  <img src={herbes} alt="" className="w-full h-full object-contain" />
                </div>
              </div>
            </div>
          </section>

          <section className="relative bg-secondaryColor">
            <img className=' w-full h-2 absolute top-0' src={line} alt='1' />
            <div className="container mx-auto text-center py-5">
              {/* <h1 className="text-2xl text-textWhite font-medium mb-2">OUR FEATURES</h1> */}
              <p className="md:text-2xl text-lg text-primaryColor font-base">Why Venkataramana Ayurveda?</p>
            </div>
            <img className=' w-full h-2 absolute bottom-0' src={line} alt='1' />
          </section>
          <section className='min-h-[85vh]  bg-cover bg-no-repeat bg-center md:py-[50px] md:p-0 p-2 relative' style={{ backgroundImage: `url(${features})` }}>
            <div className="container mx-auto">
              <div className="md:w-[80%] md:text-xl text-sm text-textWhite font-base">
                <p className=''>Ayurveda, a traditional system of medicine originating from ancient India, offers a  holistic approach to health and wellness. There are several reasons why people choose to use Ayurvedic products:</p>
              </div>
              <div className="md:px-10 my-10">
                <ul className="text-textWhite font-base  mt-10 flex flex-col gap-4 ">
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={ingredients} alt='/' />
                    <span className='font-normal md:w-[86%]   md:text-[16px] text-sm'>Natural Ingredients: Ayurvedic products are typically formulated using natural ingredients such as herbs, roots, flowers, and oils. This emphasis on natural ingredients is appealing to those who prefer to avoid synthetic chemicals and additives.</span>
                  </li>
                  <li className="md:flex gap-4  items-center "> <img className='md:w-8 w-12 md:h-8 h-12' src={Holistic} alt='/' />
                    <span className='font-normal md:w-[86%] md:text-[16px] text-sm'>Holistic Approach: Ayurveda considers the interconnectedness of the body, mind, and spirit in achieving overall health and wellness. Aurvedic products often address not just the symptoms of a condition but also its underlying causes providing a holistic approach to health.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Personalized} alt='/' />
                    <span className='font-normal md:w-[86%] md:text-[16px] text-sm'> Personalized Care: Ayurveda recognizes that each individual is unique, and therefore, treatments and products are often tailored to suit a person's specific constitution (dosha) and needs. This personalized approach is appealing to those seeking customized healthcare solutions.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={TimeTested} alt='/' />
                    <span className='font-normal md:w-[86%] md:text-[16px] text-sm'>Time-Tested Tradition: Ayurveda has a rich history spanning thousands of years and has been passed down through generations. Many people trust Ayurvedic remedies due to their long history of use and the wealth of empirical evidence supporting their efficacy.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Balance} alt='/' />
                    <span className='font-normal md:w-[86%] md:text-[16px] text-sm'>Promotion of Balance: Ayurveda aims to restore balance to the body and mind by addressing imbalances in the doshas (Vata, Pitta, and Kapha). Ayurvedic products are formulated to help bring the doshas back into harmony, thereby promoting overall health and vitality.</span></li>
                  <li className="md:flex gap-4  items-center "><img className='md:w-8 w-12 md:h-8 h-12' src={Complementary} alt='/' />
                    <span className='font-normal md:w-[86%] w-24 md:text-[16px] text-sm'>Complementary Healthcare: Many people use Ayurvedic products alongside conventional medical treatments as a complementary approach to health and wellness. Ayurveda can often provide support for various health conditions and help manage symptoms.</span></li>
                  <li></li>
                </ul>
              </div>
              <div className="md:w-[80%] md:text-lg text-sm text-textWhite font-base">
                <p className=''>Overall, the use of Ayurvedic products aligns with a growing trend towards natural, holistic, and personalized approaches to health and wellness.</p>
              </div>
            </div>


          </section>

        </div>
      </Layouts>

    </>
  );
};

export default Home;