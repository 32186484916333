import axios from "axios";

const http = axios.create({
    baseURL: "http://3.128.43.131:8080/ayu",
    headers: {
        "Content-type": "application/json",
    },
});

http.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");

        if (token) {
            config.headers.Authorization = `Bearer ${ token }`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default http;
