import React, { useState, useEffect, } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assests/Logo.png";
import venkat from "../Assests/logo-venkat.png";
import Headrerimage from "../Assests/images/Header.png";
import iyer from "../Assests/images/Hiyer.png";

import { Drawer } from "antd";


const Header = () => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  


  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setActiveLink(path === "" ? "home" : path);
  }, [location.pathname]);

  const handlehomeClick = () => {
    navigate('/');

  }
  

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsHeaderFixed(offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const handleActiveLink = (link) => {
    localStorage.setItem("active", link);
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  };

 
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
 
 
  return (
    <>

      <div className={`w-full ${isHeaderFixed ? "fixed top-0 z-10" : ""}`}>
        <div className=" w-full md:h-[125px] flex   items-center text-xl text-white " style={{ backgroundImage: `url(${Headrerimage})` }}>
          <div className="container w-full  flex md:justify-between items-center py-2 h-[80px] md:h-auto">
            <div className="md:w-[50%] w-[100%]  flex md:justify-start justify-between items-center gap-4 md:gap-1">
              {/* <img onClick={handlehomeClick} className=" md:w-[80%] cursor-pointer" src={logo} alt="1" /> */}
              <div className="flex">
                <img onClick={handlehomeClick} className="w-[20%] cursor-pointer" src={logo} alt="logo" />
                <img onClick={handlehomeClick} className="w-[55%] cursor-pointer" src={venkat} alt="venkat" />
              </div>
            </div>


            <div className="hidden md:flex justify-end w-[45%] gap-5">
              <div
                className={`${activeLink === "home" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("home")}
              >
                <Link to="/">Home</Link>
              </div>
              <div
                className={`${activeLink === "about" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("about")}
              >
                <Link to="/about">About Us</Link>
              </div>

              <div
                className={`${activeLink === "contact" ? "text-Orange" : ""}`}
                onClick={() => handleActiveLink("contact")}
              >
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>

            <div className="md:w-[29%] w-[5%]   md:flex justify-center items-center ">
            <img className="w-[90px] md:block hidden " src={iyer} alt="1" />
          </div>
          <div className="md:hidden block text-white pr-2" onClick={showDrawer}><MenuOutlined /></div>


          </div >

         
          
        </div >



      </div >

      <Drawer className="drawerclose " title={<div className="w-44 "><img src={venkat} alt="Logo" /></div>} placement="left" onClose={onClose} open={open}>
        <div className="flex flex-col gap-4  text-base cursor-pointer ">
          <div className={`${activeLink === "home" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("home")}>
            <Link to='/'>Home</Link></div>
          <div className={`${activeLink === "about" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("about")}>
            <Link to="/about">About Us</Link></div>
          <div className={`${activeLink === "contact" ? "text-Orange" : ""}`}
            onClick={() => handleActiveLink("contact")}>
            <Link to="/contact">Contact Us</Link>
          </div>
        </div>

      </Drawer>
    </>
  );
};

export default Header;