import React from 'react'
import Header from '../../Shared/Header'
import Footer from '../../Shared/Footer'
import ScrollTop from'../../Assests/images/scrolldown.svg'

const Layouts = ({ children }) => {

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };
  
  return (
    <div >
      <Header />
      <div>
        {children}
        <div
        className="fixed right-0 bottom-0 cursor-pointer "
        onClick={handleClick}
      >
        <img src={ScrollTop} alt="images" className="w-[90px] h-[50px] md:w-full" />
      </div>
      </div>

      <Footer/>
    </div>


  )
}

export default Layouts











